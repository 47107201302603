import Blockly from 'blockly'
import { netlogoGenerator } from '@/blocklyHelpers/netlogoGenerator';
export let wildfires = {};

wildfires = {
    "categories": [
        {
            "kind": "category",
            "name": "Properties",
            "categorystyle": "smoke_properties_category",
            "expanded": true,
            "contents": [
                {
                    "kind": "block",
                    "type": "create_smoke"
                },
                // {
                //     "kind": "block",
                //     "type": "set_size"
                // },
                // {
                //     "kind": "block",
                //     "type": "set_type"
                // },
                // {
                //     "kind": "block",
                //     "type": "set_color"
                // },
                // {
                //     "kind": "block",
                //     "type": "set_heading"
                // },
                // {
                //     "kind": "block",
                //     "type": "relationship"
                // },
                // {
                //     "kind": "block",
                //     "type": "forward"
                // }
            ]
        },
        {
            "kind": "category",
            "name": "Action",
            "categorystyle": "smoke_action_category",
            "expanded": true,
            "contents": [
                {
                    "kind": "block",
                    "type": "spread_out"
                },
                {
                    "kind": "block",
                    "type": "get_blown"
                }
            ]
        },
        {
            "kind": "category",
            "name": "Control",
            "categorystyle": "smoke_control_category",
            "expanded": true,
            "contents": [
                {
                    "kind": "block",
                    "type": "for_smoke"
                }
            ]
        }
    ]
}

Blockly.Blocks['create_smoke'] = {
    init: function () {
        this.jsonInit({
            "message0": "create smoke particles %1",
            "args0": [
                {
                    "type": "input_dummy",
                    "name": "EMPTY"
                }
            ],
            "previousStatement": null,
            "nextStatement": null,
            "style": "tree_behavior_blocks",
            "mutator": "general_mutator",
            "tooltip": "",
            "helpUrl": ""
        });
        let dataObj = {
            'unpackBlocks': [
                {
                    parent: 'temp_weight'
                },
                {
                    parent: 'humidity_weight',
                },
                {
                    parent: 'wind_weight_fire',
                },
            ],
            'fieldData': {
                'TEMP': 'NOEFFECT',
                'T_WEIGHT': 1,
                'HUMIDITY': 'NOEFFECT',
                'H_WEIGHT': 1,
                'WIND': 'NOEFFECT',
                'W_WEIGHT': 1,
                'CHANGED': false
            },
            'contentXml': ''
        };
        // define unpacked blocks and default values
        this.data = JSON.stringify(dataObj);
    },
    onchange: function () {
        const dataObj = JSON.parse(this.data);
        // update connected blocks if visible
        if (this.getInput('EXPANDED_STATEMENT')) {
            let count = 0;
            for (let child of this.getChildren()) {
                if (count === 0) {
                    dataObj.contentXml = Blockly.Xml.domToText(Blockly.Xml.blockToDom(child));
                }
                else {
                    dataObj.contentXml += Blockly.Xml.domToText(Blockly.Xml.blockToDom(child));
                }
                ++count;
            }
        }
        this.data = JSON.stringify(dataObj);
    }
}

Blockly.Blocks['move'] = {
    init: function () {
        this.jsonInit({
            "message0": "move %1",
            "args0": [
                {
                    "type": "input_dummy",
                    "name": "EMPTY"
                }
            ],
            "previousStatement": null,
            "nextStatement": null,
            "style": "smoke_behavior_blocks",
            "mutator": "general_mutator",
        });
        let dataObj = {
            'unpackBlocks': [
                {
                    parent: 'diffusion'
                },
                {
                    parent: 'wind_weight_move',
                },
            ],
            'fieldData': {
                'DIFFUSE': 'NOEFFECT',
                'WIND': 'NOEFFECT',
                'WEIGHT': 1,
                'CHANGED': false
            },
            'contentXml': ''
        };
        // define unpacked blocks and default values
        this.data = JSON.stringify(dataObj);
    },
    onchange: function () {
        const dataObj = JSON.parse(this.data);
        // update connected blocks if visible
        if (this.getInput('EXPANDED_STATEMENT')) {
            let count = 0;
            for (let child of this.getChildren()) {
                if (count === 0) {
                    dataObj.contentXml = Blockly.Xml.domToText(Blockly.Xml.blockToDom(child));
                }
                else {
                    dataObj.contentXml += Blockly.Xml.domToText(Blockly.Xml.blockToDom(child));
                }
                ++count;
            }
        }
        this.data = JSON.stringify(dataObj);

    }
}

Blockly.defineBlocksWithJsonArray([
    {
        "type":"create_smoke",
        "message0": "create smoke particles",
        "previousStatement": null,
        "nextStatement": null,
        "tooltip": "",
        "helpUrl": "",
        "style": "smoke_behavior_blocks"
    },
    {
        "type":"for_smoke",
        "message0":"for smoke particles %1 %2",
        "args0": [
            {
                "type": "input_dummy"
            },
            {
                "type":"input_statement",
                "name": "FOR"
            }
        ],
        "previousStatement": null,
        "nextStatement": null,
        "style": "smoke_control_blocks",
        "tooltip": "",
        "helpUrl": ""
    },
    {
        "type": "spread_out",
        "message0": "spread out",
        "previousStatement": null,
        "nextStatement": null,
        "tooltip": "",
        "helpUrl": "",
        "style": "smoke_action_blocks"
    },
    {
        "type": "get_blown",
        "message0": "get blown",
        "previousStatement": null,
        "nextStatement": null,
        "tooltip": "",
        "helpUrl": "",
        "style": "smoke_action_blocks"
    },
    {
        "type": "set_size",
        "message0": "set size %1 µm",
        "args0": [
            {
                "type": "field_slider",
                "name": "SIZE",
                "value": 2,
                "min": 1,
                "max": 10,
            },
        ],
        "previousStatement": null,
        "nextStatement": null,
        "tooltip": "",
        "helpUrl": "",
        "style": "smoke_behavior_unpacked_blocks"
    },
    {
        "type": "set_type",
        "message0": "set type %1",
        "args0": [
            {
                "type":"field_dropdown",
                "name": "TYPE",
                "options": [
                    [
                        "fine",
                        "FINE"
                    ],
                    [
                        "course",
                        "COURSE"
                    ]
                ]
            }
        ],
        "previousStatement": null,
        "nextStatement": null,
        "tooltip": "",
        "helpUrl": "",
        "style": "smoke_behavior_unpacked_blocks"
    },
    {
        "type": "set_color",
        "message0": "set color %1",
        "args0": [
            {
                "type": "field_dropdown",
                "name": "TYPE",
                "options": [
                    [
                        "red",
                        "RED"
                    ],
                    [
                        "green",
                        "GREEN"
                    ],
                    [
                        "blue",
                        "BLUE"
                    ],
                    [
                        "gray",
                        "GRAY"
                    ]
                ]
            }
        ],
        "previousStatement": null,
        "nextStatement": null,
        "tooltip": "",
        "helpUrl": "",
        "style": "smoke_behavior_unpacked_blocks"
    },
    {
        "type": "set_heading",
        "message0": "set heading %1",
        "args0": [
            {
                "type": "field_dropdown",
                "name": "TYPE",
                "options": [
                    [
                        "least particles",
                        "LEASTPARTICLES"
                    ],
                    [
                        "wind direction",
                        "WINDDIRECTION"
                    ]
                ]
            }
        ],
        "previousStatement": null,
        "nextStatement": null,
        "tooltip": "",
        "helpUrl": "",
        "style": "smoke_behavior_unpacked_blocks"
    },
    {
        "type": "relationship",
        "message0": "as size goes %1 %2 step size goes %3",
        "args0": [
            {
                "type": "field_dropdown",
                "name": "SIZE",
                "options": [
                    [
                        "up",
                        "UP"
                    ],
                    [
                        "down",
                        "DOWN"
                    ]
                ]
            },
            {
                "type": "input_dummy"
            },
            {
                "type": "field_dropdown",
                "name": "STEPSIZE",
                "options": [
                    [
                        "up",
                        "UP"
                    ],
                    [
                        "down",
                        "DOWN"
                    ],
                    [
                        "the same",
                        "SAME"
                    ]
                ]
            }
        ],
        "previousStatement": null,
        "nextStatement": null,
        "style": "smoke_action_unpacked_blocks",
        "tooltip": "",
        "helpUrl": ""
    },
    {
        "type": "forward",
        "message0": "forward",
        "previousStatement": null,
        "nextStatement": null,
        "tooltip": "",
        "helpUrl": "",
        "style": "smoke_action_unpacked_blocks"
    },
]);

// GEN BLOCKS
// Setup blocks
netlogoGenerator['set'] = function (block) {
    const statement_members = netlogoGenerator.statementToCode(block, 'SET');
    const code = 'to blocks-set\n' +
        netlogoGenerator.INDENT + 'setup\n' +
        statement_members +
        netlogoGenerator.INDENT + 'reset-ticks\n' +
        'end\n';
    return code;
};
netlogoGenerator['go'] = function (block) {
    const statement_members = netlogoGenerator.statementToCode(block, 'GO');
    const code = 'to blocks-go\n' +
        // netlogoGenerator.INDENT + 'mouse_click\n' +
        netlogoGenerator.INDENT + 'go\n' +
        statement_members +
        netlogoGenerator.INDENT + 'tick-advance tick-delta\n' +
        netlogoGenerator.INDENT + 'display\n' +
        'end\n';
    return code;
};

netlogoGenerator['mouse_click'] = function (block) {
    let statement_members = netlogoGenerator.INDENT + 'set mouse-up? false\n' + netlogoGenerator.statementToCode(block, 'MOUSE');

    let ifContents = 'if mouse-down? \n[\n' +
        statement_members + ']';
    ifContents = Blockly.JavaScript.prefixLines(ifContents, netlogoGenerator.INDENT);

    let elseContents = '[\n' + netlogoGenerator.INDENT + 'if not mouse-down?\n' + netlogoGenerator.INDENT + '[\n' + netlogoGenerator.INDENT + netlogoGenerator.INDENT + 'set mouse-up? true\n' + netlogoGenerator.INDENT + ']\n' + ']\n'
    elseContents = Blockly.JavaScript.prefixLines(elseContents, netlogoGenerator.INDENT);
    let code = 'ifelse mouse-up? != false' + '\n[\n' + ifContents + '\n]\n';

    code = Blockly.JavaScript.prefixLines(
        code, netlogoGenerator.INDENT) + elseContents;

    // return 'to mouse_click\n' + code + 'end\n';
    return 'to mouse_click\n' + code + 'end\n';
};

// If/elseif/else condition
netlogoGenerator['controls_if'] = function (block) {
    let n = 0;
    let usedTreeOnFire;
    let code = '', branchCode, conditionCode;

    if (block.inputList.length === 2) {
        code += 'if ';
    }
    else {
        code += '(ifelse ';
    }

    do {
        conditionCode = netlogoGenerator.valueToCode(block, 'IF' + n,
            netlogoGenerator.ORDER_NONE) || 'false';
        usedTreeOnFire = conditionCode.includes('color != green and  color > (red - 5) and  color > (red - fire-intensity)');
        branchCode = (usedTreeOnFire ? 'set tree-on-fire 1\n' : 'set tree-on-fire 0\n') + netlogoGenerator.statementToCode(block, 'DO' + n);
        if (Blockly.JavaScript.STATEMENT_SUFFIX) {
            branchCode = Blockly.JavaScript.prefixLines(
                Blockly.JavaScript.injectId(Blockly.JavaScript.STATEMENT_SUFFIX,
                    block), netlogoGenerator.INDENT) + branchCode;
        }
        code += (n > 0 ? '\n' : '') + conditionCode + '\n[\n' + branchCode + ']';
        n++;
    } while (block.getInput('IF' + n));

    if (block.getInput('ELSE') || Blockly.JavaScript.STATEMENT_SUFFIX) {
        branchCode = netlogoGenerator.statementToCode(block, 'ELSE');
        if (Blockly.JavaScript.STATEMENT_SUFFIX) {
            branchCode = Blockly.JavaScript.prefixLines(
                Blockly.JavaScript.injectId(Blockly.JavaScript.STATEMENT_SUFFIX,
                    block), netlogoGenerator.INDENT) + branchCode;
        }
        code += '\n[\n' + branchCode + ']';
    }

    (block.inputList.length === 2) ? code += '\n' : code += ')\n';
    return code;
};

// SMOKE PROPAGATION BLOCK DEFINITIONS
// Properties
// Create Smoke
netlogoGenerator['create_smoke'] = function () {
    let code = 'set smoke-fine? true\n' + 
        'set smoke-fine-size 2.5\n' +
        'set smoke-fine-color "blue"\n'
    return code;
}
// Action
netlogoGenerator['spread_out'] = function () {
    return 'move-smoke-downhill\n';
}

netlogoGenerator['get_blown'] = function () {
    return 'wind-effect\n';
}
// Control
netlogoGenerator['for_smoke'] = function (block) {
    const statement_members = netlogoGenerator.statementToCode(block, 'FOR');
    let code = 'ask smokes' + '\n' + '[\n' + statement_members + ']\n';
    return code;
}